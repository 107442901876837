/* eslint-disable no-restricted-globals */
import React, {useState} from 'react';
import Base64 from "./base64";
import {defaultText} from "./constants";

function GenerateUrl() {
  const [name, setName] = useState('');
  const [text, setText] = useState(defaultText);
  const [isChangeText, setChangeText] = useState(false);
  const [generated, setGenerated] = useState('');

  const encodeStr = (str) => {
    const encoder = new TextEncoder();
    const encodedArray = encoder.encode(str);
    const encodedString = btoa(String.fromCharCode(...encodedArray));
    return Base64.encode(encodedString);
  }

  const generateLink = (event) => {
    event.preventDefault();
    if (!name) {
      alert('Введите имя!')
      return
    }
    const encodedName = encodeStr(name);
    let url = `${location.origin}/?name=${encodedName}`;
    if (isChangeText) {
      const encodedText = encodeStr(text);
      url += `&text=${encodedText}`
    }
    setGenerated(url);
    navigator.clipboard.writeText(url)
      .then(() => {
        alert('Ссылка скопирована в буфер обмена')
      })
      .catch(() => {

      });
  }

  const copyLink = () => {
    navigator.clipboard.writeText(generated)
      .then(() => {
        alert('Ссылка скопирована')
      })
      .catch(() => {

      });
  }

  const handleNameChange = (event) => {
    if (event.target.value.length > 99) {
      alert('Максимальная длинна имени - 100 символов');
      return
    }
    setName(event.target.value);
  }

  const handleTextChange = (event) => {
    if (event.target.value.length > 999) {
      alert('Максимальная длинна текста - 1000 символов');
      return
    }
    setText(event.target.value);
  }

  return (
    <form onSubmit={generateLink}>
      <p>Напиши сюда имя девушки, для которой нужно сгенерировать ссылку</p>
      <div>
        <input placeholder="Введите имя" type="text" value={name} onChange={handleNameChange}/>
      </div>
      <br />
      <span>Изменить текст?</span>
      <input type="checkbox" checked={isChangeText} onChange={(event) => setChangeText(event.target.checked)}/>
      <br />
      {isChangeText ? (
        <>
          <p>Напиши сюда поздравление, которое хочешь, чтобы она увидела</p>
          <div>
            <textarea rows={7} placeholder="Введите поздравление" type="text" value={text} onChange={handleTextChange}/>
          </div>
          <br />
        </>
      ) : (<br />)}
      <button onClick={generateLink}>Сгенерировать</button>
      {generated ? (<p style={{cursor: 'pointer'}} onClick={copyLink}>{generated}</p>) : null}
    </form>
  );
}

export default GenerateUrl;