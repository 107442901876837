import React from 'react';
import Base64 from "../base64";
import {defaultText} from "../constants";

function Step2(props) {
  // eslint-disable-next-line no-restricted-globals
  const searchParams = new URLSearchParams(location.search);
  let decodedString = defaultText;
  try {
    const text = searchParams.get('text') || defaultText;
    const decodedArray = Uint8Array.from(atob(Base64.decode(text)), c => c.charCodeAt(0));
    const decoder = new TextDecoder();
    decodedString = decoder.decode(decodedArray) || defaultText;
  } catch {

  }
  return (
    <div style={{fontSize: '1.5rem'}}>
      {decodedString.split('\n').map((text) => (
        <p key={text}>{text}</p>
      ))}
      <h1>❤️</h1>
    </div>
  );
}

export default Step2;