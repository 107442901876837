import React from 'react';
import Base64 from "../base64";

function Step1({ onClick }) {
  // eslint-disable-next-line no-restricted-globals
  const searchParams = new URLSearchParams(location.search);
  let decodedString = 'Котик';
  try {
    const name = searchParams.get('name') || 'котик';
    const decodedArray = Uint8Array.from(atob(Base64.decode(name)), c => c.charCodeAt(0));
    const decoder = new TextDecoder();
    decodedString = decoder.decode(decodedArray);
  } catch {

  }
  return (
    <div>
      <h1>Привет, {decodedString} ❤️!</h1>
      <p>Надеюсь твой день проходит хорошо и ты уже получила много поздравлений</p>
      <p>Я бы тоже хотел тебя поздравить и кое в чем тебе признаться</p>
      <h2>Готова?</h2>
      <button type="button" onClick={onClick}>Готова!</button>
    </div>
  );
}

export default Step1;