import './App.css';
import {useState} from "react";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";

function App() {
  const [step, setStep] = useState(1);
  const isStep1 = step === 1;
  const isStep2 = step === 2;
  return (
    <div className="App">
      {isStep1 ? (<Step1 onClick={() => setStep(2)} />): null}
      {isStep2 ? (<Step2 />) : null}
    </div>
  );
}

export default App;
